// services
import {
  findAppModule,
  moduleNames
} from "@/services/solution/solutionService";
import { notImplementedComputed } from "@/services/error/errorMessages";

// vuex
import { createNamespacedHelpers } from "vuex";
const { mapMutations } = createNamespacedHelpers("solution");

// store
import { SET_APPLICATION } from "@/store/solution/mutation-types";

/**
 * Contains options (adhere to the DRY principle) & code re-usability for app's Home Vue components
 */
export const baseHomeMixin = {
  components: {
    AppLayout: () => import("@/views/shared/layout/AppLayout")
  },
  data() {
    return {
      applicationNames: moduleNames
    };
  },
  computed: {
    /**
     * Abstract computed: Provide Application Name of an appModule to be selected when a home page is displayed
     * Note: This property has to be be implemented by supper class
     * @returns {String|string}
     */
    selectApplicationName() {
      throw notImplementedComputed("selectApplicationName");
    }
  },
  methods: {
    ...mapMutations({
      setApplication: SET_APPLICATION
    }),
    /**
     * Set Current Application which will be selected in the App-Bar
     */
    setCurrentApplication() {
      this.setApplication(findAppModule(this.selectApplicationName));
    }
  },
  /**
   * created hook
   * note: Mixin hooks will be called before the component’s created hooks.
   */
  created() {
    this.setCurrentApplication();
  }
};
