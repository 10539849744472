<template>
  <v-app>
    <!--  Application Bar-->
    <app-bar></app-bar>

    <!-- Search content -->
    <v-main>
      <v-container fluid>
        <search-content></search-content>
      </v-container>
    </v-main>

    <!-- Application Footer -->
    <app-footer></app-footer>
  </v-app>
</template>

<script>
import { baseHomeMixin } from "@/mixins/shared/base/home/baseHomeMixin";
export default {
  name: "SearchHome",
  mixins: [baseHomeMixin],
  components: {
    AppBar: () => import("@/components/shared/core/bars/AppBar"),
    AppFooter: () => import("@/components/shared/core/footers/AppFooter"),
    SearchContent: () => import("@/views/search/SearchContent.vue")
  },
  computed: {
    selectApplicationName() {
      return this.applicationNames.Search;
    }
  }
};
</script>
